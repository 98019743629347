<template>
  <div>
    <progress-shimmer v-if="loader" />
    <div v-else class="total-project-hour-dashboard hrinoutinfo autoDayout mxHeight" style="height:550px">
      <div class="d-flex align-items-center justify-content-between hrFlatpicker">
        <div>
          <h3 class="mb-0 total-hours-time">
            <span>{{ label }}</span>
            <!-- class="text-color-blue" -->
          </h3>

        </div>
        <div v-if="type !== 'top'" class="d-flex justify-content-end align-items-center">
          <h4 class="text-color-blue fw-600 m-0">
            Filters :
          </h4>
          <custom-dropdown v-if="userInfo && userInfo.role === 'SA'" v-model="selectUser" :options="users"
            label="Manage By" />
          <custom-dropdown v-model="month" :options="optionsMonths" :hide-close="true" :search-box="false"
            label="Date/Month" />

        </div>
      </div>
      <div class="all-project-progress hr-dashboard" style="max-height:100%"
        :class="{ 'overflow-hidden': !chartData || !chartData.length }">
        <apexchart v-if="chartData && chartData.length" ref="chart" type="bar" height="450" :options="chartOptions"
          :series="[{ name: '', data: chartData }]" :class="type === 'top' ? 'custom-apex-chart' : null"
          @click="handleBarClick" />
        <div v-show="!chartData || !chartData.length" class="overflow-hidden">
          <img src="@/assets/images/nodatafound/nodata.svg" alt="no-data" class="no-data-img no-data-dashboard">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import * as moment from 'moment/moment'
import ProgressShimmer from '../../shimmer/ProgressShimmer.vue'
import CustomDropdown from '../../../components/dropdown/customDropdown.vue'
import { eventBus } from '../../../main'

export default {
  name: 'DefectByMember',
  components: {
    apexchart: VueApexCharts, ProgressShimmer, CustomDropdown,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    optionsMonths: {
      type: Array,
      required: false,
      default: () => []
    },
    users: {
      type: Array,
      required: false,
      default: () => []
    },
    colors: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data() {
    return {
      month: '3',
      selectUser: null,
      loader: false,
      chartData: [],
      project_ids: [],
      project_slugs: [],
      chartOptions: {
        colors: this.colors,

        chart: { type: 'bar', height: 450, toolbar: { show: true, tools: { download: false } } },

        plotOptions: { bar: { borderRadius: 20, horizontal: false } },
        dataLabels: { enabled: false },
        labels: [],
        xaxis: { categories: [] },
        tooltip: {
          y: {
            formatter: val => {
              const totalHours = Math.floor(val)
              const totalMinutes = Math.round((val - totalHours) * 60)

              // Format hours and minutes
              const formattedHours = totalHours.toString().padStart(2, '0')
              const formattedMinutes = totalMinutes.toString().padStart(2, '0')

              return `${formattedHours}hr ${formattedMinutes}m`
            },
          },
        },
      },
    }
  },

  watch: {
    month: 'commonApiCallingFunction',
    selectUser: 'commonApiCallingFunction',
  },

  mounted() {
    this.commonApiCallingFunction()
  },

  methods: {
    commonApiCallingFunction() {
      this.chartData = []
      if (this.type === 'top') {
        this.getProjectListWithMaxAvgTime()
      } else {
        this.getMonthWiseAvgTime()
      }
    },

    async getProjectListWithMaxAvgTime() {
      this.loader = true
      this.chartData = []
      const response = await this.getHTTPPostResponse('project/defect/max-average-time-spent-project-defect', {}, false)
      if (response && response.data) {
        this.loader = false
        const { data } = response
        if (data.defect_average && data.defect_average.length) {
          this.chartData = data.defect_average.map(element => ((element.time_spent_minutes / 60).toFixed(2)))
          this.chartOptions.xaxis.categories = data.defect_average.map(element => element.name)
          this.project_ids = data.defect_average.map(element => element.id)
          this.project_slugs = data.defect_average.map(element => element.slug)
          setTimeout(() => {
            this.$refs.chart.updateOptions({ ...this.chartOptions })
          }, 100)
        }
      }
      this.loader = false
    },

    async getMonthWiseAvgTime() {
      this.chartData = []
      this.loader = true
      const input = {
        user_id: this.selectUser || null,
        start_date: moment().endOf('month').add(1, 'd').subtract(this.month, 'months')
          .format('YYYY-MM-DD'),
        end_date: moment().endOf('month').add(1, 'd').format('YYYY-MM-DD'),
      }

      const response = await this.getHTTPPostResponse('project/defect/max-average-time-spent-defect', input, false)

      if (response && response.data) {
        this.loader = false
        const { data } = response
        if (data.defect_average && data.defect_average.length) {
          // Create an array of all months between start_date and end_date
          const startDate = moment(input.start_date)
          const endDate = moment(input.end_date)
          const allMonths = []
          while (startDate.isBefore(endDate)) {
            allMonths.push({
              month: Number(startDate.format('MM')),
              label: startDate.format('MMMM'),
              year: startDate.year(),
              time_spent_minutes: 0,
            })
            startDate.add(1, 'month')
          }

          // Merge the allMonths array with the response data
          const mergedData = allMonths.map(month => {
            const foundData = data.defect_average.find(element => element.month == month.month && element.year === month.year)

            return foundData ? { ...foundData, label: month.label, time_spent_minutes: parseInt(foundData.time_spent_minutes) } : { ...month, time_spent_minutes: 0 }
          })

          // Update chart data
          this.chartData = mergedData.map(element => ((Number(element.time_spent_minutes) / 60).toFixed(2)))

          // Update chart options
          this.chartOptions.xaxis.categories = mergedData.map(element => element.label)
          this.$refs.chart.updateOptions({ ...this.chartOptions })
        }
      }
      this.loader = false
    },

    handleBarClick(event, chart, chartContext) {
      if (this.type === 'top' && chartContext.dataPointIndex !== -1) {
        this.$store.commit('app/UPDATE_PROJECT_ID', this.project_ids[chartContext.dataPointIndex])
        this.$router.push({
          name: 'projectTask',
          params: {
            id: 'qassure',
            id2: this.project_slugs[chartContext.dataPointIndex],
            id3: this.project_ids[chartContext.dataPointIndex],
            id4: 'defects',
            page_name: 'q-dashboard',
          },
          query: {
            status: 'all',
          },
        })
        eventBus.$emit('qassureTabOpen', true)
      }
    },
  },
}
</script>
